@let hasData = startDate && endDate;

<div class="opportunity-timeline flex--column gap-y-1">
  <div
    #timeline
    class="opportunity-timeline__container flex--row"
    [class.opportunity-timeline__container--no-data]="!hasData"
  >
    @if (hasData) {
      <div #container class="offset-container offset-container--left"></div>
      <div
        class="timeline-grid"
        [class.timeline-grid--single-month]="months?.length === 1"
        [class.timeline-grid--dual-month]="months?.length === 2"
        [style.--number-of-days]="days?.length || 0"
        role="grid"
      >
        <div class="header--background"></div>
        <div class="months-header" role="row">
          @for (month of months; track $index) {
            <div class="months-header__container" [style.grid-column]="month.col" role="columnheader">
              <p class="months-header__text">
                {{ month.name + ' ' + month.year }}
              </p>
            </div>
          }
        </div>
        <div class="mondays-header" role="row">
          @for (monday of mondays; track $index) {
            <div [style.grid-column]="monday.col" role="columnheader">
              <p class="mondays-header__text">{{ monday.text }}</p>
            </div>
          }
        </div>
        <div class="calendar-weeks-header" role="row">
          @for (monday of mondays; track $index) {
            <div [style.grid-column]="monday.col" role="columnheader">
              <p class="calendar-weeks-header__text">
                {{ ('opportunities:timeline.calendarWeek' | i18next) + monday.kw }}
              </p>
            </div>
          }
        </div>
        @if (showToday) {
          <div
            #todayEl
            [appTimelineEntry]
            [startDate]="today"
            [endDate]="today"
            class="today"
            [style.z-index]="1"
          ></div>
        }
        <div class="entries">
          <ng-content></ng-content>
        </div>
        @for (month of months; track $index) {
          @if ($index !== 0) {
            <div class="month-separator" [style.grid-column]="month.col"></div>
          }
        }
        @for (dayOff of noWorkingDays; track $index) {
          <div class="no-working-day-stripe" [style.grid-column]="dayOff.col"></div>
        }
      </div>
      <div class="offset-container offset-container--right"></div>
    } @else {
      <div>
        <p>{{ 'opportunities:timeline.missingDates' | i18next }}</p>
      </div>
    }
  </div>
  <div class="footer flex--row">
    <ino-button [class.hidden]="!showToday" dense variant="text" (click)="scrollToToday()">
      {{ 'common:timeline.focus_today' | i18next }}
    </ino-button>
    <div class="flex--row gap-x-1">
      @for (labelColor of legend | keyvalue: unsorted; track $index) {
        <div class="flex--row gap-x-1">
          <div class="legend__dot" [style.background]="labelColor.value"></div>
          <small>{{ labelColor.key }}</small>
        </div>
      }
      <div class="flex--row gap-x-1">
        <div class="legend__dot"></div>
        <small>
          {{ 'common:timeline.legend.today' | i18next }}
        </small>
      </div>
    </div>
  </div>
</div>
