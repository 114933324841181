import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen } from 'rxjs/operators';
import { NotificationService } from '@shared/services/notification.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      // retry internal server errors
      retryWhen((originalError) =>
        originalError.pipe(
          mergeMap((error: HttpErrorResponse, numberOfRetries) => {
            if (numberOfRetries < MAX_RETRIES && error.status == 500) {
              return of(error).pipe(delay(RETRY_DELAY));
            }

            throwError(() => new Error(error.message));
          }),
        ),
      ),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);

        return throwError(() => new Error(error.message));
      }),
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      errorMsg = `Error: ${error.error.message}`;
    } else {
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
      this.handleHTTPError(error);
    }

    console.error(errorMsg);
  }

  private handleHTTPError(error: HttpErrorResponse) {
    switch (error.status) {
      case 401: {
        this.notificationService.addErrorNotification('errors:errors.notAuthenticated');
        return;
      }
      default: {
        this.notificationService.addErrorNotification('errors:errors.generic');
        return;
      }
    }
  }
}
