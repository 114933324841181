import { Pipe, PipeTransform } from '@angular/core';
import { I18NextFormatPipe } from 'angular-i18next';

@Pipe({
  name: 'currencyPer',
})
export class CurrencyPerPipe implements PipeTransform {
  constructor(private i18nextFormatPipe: I18NextFormatPipe) {}

  transform(value: number, unit?: string): string {
    if (!value && value !== 0) return '-';

    const appendix = unit ? `/${unit}` : '';

    return this.i18nextFormatPipe.transform(value, { format: 'currency' }) + appendix;
  }
}
