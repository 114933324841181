<div class="notifications-container">
  @for (note of notificationService.notifications$ | async; track note.id) {
    <ino-snackbar
      [id]="'notification-' + note.id"
      [type]="note.type"
      [attr.data-cy]="'notification-' + note.type"
      [attr.data-testid]="'notification-' + note.type"
      [timeout]="note.timeout"
      [stayVisibleOnHover]="note.stayVisibleOnHover"
      [actionText]="note.actionText"
      (actionClick)="note.onActionClick($any($event))"
      (hideEl)="notificationService.removeNotification(note.id)"
    >
      {{ note.message }}
    </ino-snackbar>
  }
</div>
