import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from 'apollo-angular';
import { onError } from '@apollo/client/link/error';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { HttpHeaders, HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { Injector, NgModule } from '@angular/core';
import { ErrorInterceptor } from '@shared/services/error-interceptor.service';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { NotificationService } from '@shared/services/notification.service';
import { OperationDefinitionNode, OperationTypeNode } from 'graphql';
import { ErrorStateService } from '@shared/services/error-state.service';
import { SentryLink } from 'apollo-link-sentry';
import { APP_CONFIG } from './configuration.module';
import { GraphQLErrorMessage } from '@shared/types';
import { AttachBreadcrumbsOptions } from 'apollo-link-sentry/lib-esm/options';

// Large Oppties will cause server-side race condition due to parallel oppty & assignment & proposal creation
const BATCH_MAX = 999;

const cacheDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const sentryBreadcrumbsOptions: AttachBreadcrumbsOptions = {
  includeQuery: true,
  includeVariables: true,
  includeFetchResult: false,
  includeError: true,
  includeCache: false,
  includeContext: false,
  transform: undefined,
};

export const createDefaultClient = (
  httpLink: HttpLink,
  notificationService: NotificationService,
  errorStateService: ErrorStateService,
  injector: Injector,
): ApolloClientOptions<NormalizedCacheObject> => ({
  link: ApolloLink.from([
    new SentryLink({
      uri: injector.get(APP_CONFIG).apiUrl,
      attachBreadcrumbs: sentryBreadcrumbsOptions,
    }),
    onError(({ graphQLErrors, operation }) => {
      const reqDefinition = operation.query.definitions[0] as OperationDefinitionNode;

      if (
        reqDefinition.operation === OperationTypeNode.QUERY &&
        graphQLErrors?.some((err) => err.message === GraphQLErrorMessage.INTERNAL_SERVER_ERROR)
      ) {
        notificationService.addErrorNotification('errors:errors.unexpectedError');
        errorStateService.setErrorState(true);
      }

      if (
        reqDefinition.operation === OperationTypeNode.QUERY &&
        graphQLErrors?.some(
          (err) => err.message === GraphQLErrorMessage.FORBIDDEN || err.message === GraphQLErrorMessage.NOT_FOUND,
        )
      ) {
        notificationService.addErrorNotification('errors:errors.forbiddenOrNotFound');
        throw new Error(graphQLErrors.join(','));
      }
    }),
    httpLink.create({
      uri: injector.get(APP_CONFIG).apiUrl,
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*'),
      withCredentials: true,
    }),
  ]),
  defaultOptions: cacheDefaultOptions,
  cache: new InMemoryCache(),
});

export const createBatchClient = (httpBatchLink: HttpBatchLink, injector: Injector): NamedOptions => ({
  batchClient: {
    defaultOptions: cacheDefaultOptions,
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new SentryLink({
        uri: injector.get(APP_CONFIG).apiUrl,
        attachBreadcrumbs: sentryBreadcrumbsOptions,
      }),
      httpBatchLink.create({
        uri: injector.get(APP_CONFIG).apiUrl,
        headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*'),
        withCredentials: true,
        batchMax: BATCH_MAX,
      }),
    ]),
  },
});

@NgModule({
  exports: [ApolloModule],
  providers: [
    provideHttpClient(),
    {
      provide: APOLLO_OPTIONS,
      useFactory: createDefaultClient,
      deps: [HttpLink, NotificationService, ErrorStateService, Injector],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createBatchClient,
      deps: [HttpBatchLink, Injector],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class GraphQLModule {}
