@let name = employee | employeeName;
@let interactive = !disableProfileLink && employee;

<div class="avatar flex" [title]="name">
  <div class="avatar__imgBox">
    @if (isAnythingLoading$ | async) {
      <div class="spinner"></div>
    }
    @if (interactive) {
      <a class="avatar__link" [href]="employee?.id | toProfileUrl" target="_blank" appClickStopPropagation>
        <ng-container [ngTemplateOutlet]="img"></ng-container>
      </a>
    } @else {
      <div>
        <ng-container [ngTemplateOutlet]="img"></ng-container>
      </div>
    }
    <div
      [ngClass]="{
        'avatar__metadata-icon': true,
        'avatar__metadata-icon--top-right': metadataPosition === 'top-right',
        'avatar__metadata-icon--bottom-right': metadataPosition === 'bottom-right',
      }"
    >
      <ng-content select="[metadata]"></ng-content>
    </div>
  </div>
</div>

<ng-template #img>
  @if (name) {
    <ino-tooltip delay="300" colorScheme="light" placement="bottom" [label]="name">
      <div
        [ngClass]="{
          'avatar-img': true,
          'avatar-img--disable-interaction': disableProfileLink,
          'avatar-img--default': employee !== null,
          'avatar-img--placeholder': employee === null,
          'avatar-img--error': error,
        }"
      >
        @if (employee) {
          <img
            [class.img--loaded]="(isAnythingLoading$ | async) === false"
            [src]="'https://avatar.idm.inovex.io/avatar/' + employee.id + '/' + size"
            [alt]="'common:avatar.imageAltText' | i18next: { employee: name }"
            (load)="imageDidLoad()"
          />
        } @else {
          <span class="placeholder">?</span>
        }
      </div>
    </ino-tooltip>
  } @else {
    <div
      [ngClass]="{
        'avatar-img': true,
        'avatar-img--disable-interaction': disableProfileLink,
        'avatar-img--default': employee !== null,
        'avatar-img--placeholder': employee === null,
        'avatar-img--error': error,
      }"
    >
      @if (employee) {
        <img
          [class.img--loaded]="(isAnythingLoading$ | async) === false"
          [src]="'https://avatar.idm.inovex.io/avatar/' + employee.id + '/' + size"
          [alt]="'common:avatar.imageAltText' | i18next: { employee: name }"
          (load)="imageDidLoad()"
        />
      } @else {
        <span class="placeholder">?</span>
      }
    </div>
  }
</ng-template>
